import { HttpClient } from '@angular/common/http';
import { Component, HostListener} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'app/core/local-storage.service';
import { SharedService } from 'app/core/shared.service';
import { UserService } from 'app/core/user.service';
import { XcvUtilsService } from 'app/core/xcv-utils.service';
import { DataService } from 'app/helpers/data.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent {

  public isSmallScreen = false;
  public displayDialog: boolean = false;
  public recruiterForm!: FormGroup;
  public token = '';
  public hasToken = true;
  public isValid = true;
  public submitted = false;
  public countdown: number = 6; // Initial countdown value
  public processing = false;
  public redirectMessage: string = 'Token is required';

  private subscription!: Subscription;

  constructor(private fb: FormBuilder,
    private router:Router,
    private messageService: MessageService,    
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,    
    private dataService: DataService,
    private sharedService: SharedService) {
      this.createRecruiterForm();
      // // this part takes care of checking password and confirm password
      this.recruiterForm.controls['password1'].valueChanges.subscribe(() => this.validatePasswordMatch());
      this.recruiterForm.controls['password2'].valueChanges.subscribe(() => this.validatePasswordMatch());
    }


  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.checkScreenSize();
  }

  ngOnInit(): void {
    this.checkScreenSize();
    this.subscribeToQueryParams();
  }

  private subscribeToQueryParams() {
    this.subscription = this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      if (queryParams.hasOwnProperty('token') && queryParams.token !== '') {
        this.token = queryParams.token;
        this.hasToken = true;
      } else {
        this.hasToken = false;
        this.isValid = false;
      }
      if (!this.isValid) {
        this.startCountdown();
      }
    });
  }

  startCountdown(): void {
    const countdownInterval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(countdownInterval);
        this.redirectToLogin();
      }
    }, 1000);
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataService.setData('false');
    }, 10);
  }

  private createRecruiterForm() {
    this.recruiterForm = this.fb.group({
      password1: ['', [Validators.required,this.validatePassword]],
      password2: ['', [Validators.required]],
    });
  }

  validatePassword(control: any) {
    const password = control.value;
    if (!password) {
      return null;
    }
    else {
      const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^])[A-Za-z\d@$!%*?&#^]{8,}$/;

      return passwordPattern.test(control.value) ? null : { 'weakPassword': true };
    }
    // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number, and one special character
  }

  private checkScreenSize() {
    const isSmallScreen = window.innerWidth <= 767; // Match the CSS media query width
    this.isSmallScreen = isSmallScreen;
    // You can also dynamically apply a CSS class if needed
    if (isSmallScreen) {
        this.displayDialog = false;
    } else {
        this.displayDialog = true;
    }
  }

  public validatePasswordMatch() {
    const password = this.recruiterForm.controls['password1'].value;
    const confirmPassword = this.recruiterForm.controls['password2'].value;

    if (confirmPassword) {
      if (password === confirmPassword) {
        this.recruiterForm.controls['password2'].setErrors(null);
      } else {
        this.recruiterForm.controls['password2'].setErrors({ passwordMismatch: true });
      }
    } else {
      this.recruiterForm.controls['password2'].setErrors({ required: true });
    }
  }
  
  
  public onSubmit(): void {
 
    this.submitted = true;
    if (this.recruiterForm.valid) {
      const {password1, password2 } = this.recruiterForm.value;
      if (password1 === password2) {

        let payload: any = {};
        payload.password1 = password1;
        payload.password2 = password2;
        payload.token =  this.token;

        this.processing = true;

        this.subscription = this.userService.createCompany(payload).subscribe({
          next: response => {
            this.processing = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: `Company successfully registered`, life: 5000});

            // redirect to recruiter login
            this.router.navigate(['/recruiter-login']);
          },
          error: error => {
            XcvUtilsService.handleError(error, this.messageService);
            this.processing = false;
          }
        });
      } else {
        this.messageService.add({ severity: 'warn', summary: 'Warn', detail: `Passwords do not match`, life: 5000});
      }
    }
  }

  public recruiterLogin(): void {
    this.router.navigate(['/recruiter-login']);
  }

  private rerouteTasks(): void {
    // remove from storage.
    this.localStorageService.removeData('userVanity');
    this.localStorageService.removeData('userClient');
    this.localStorageService.removeData('subcriptionPlan');
    this.localStorageService.removeData('appUser');
  }

  public clientLogin() {
    this.rerouteTasks();
    this.router.navigate(['login'])
  }
  
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe;
    }
  }
}
