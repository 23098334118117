    <!-- start header -->
    <div class="row align-items-center mt-3">

        <ng-container *ngIf="!isSmallScreen">
            <div class="col-6">

                <div class="row pl-3 justify-content-center">
                    <div class="col-12">
        
                      <span (click)="clientLogin()" class="custom-link">
                        <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
                      </span>
                          
                    </div>
                  </div>
        
            </div>
    
        <div class="col-6">
            <div class="row pr-3 text-end justify-content-center">
                <div class="col-12">
                  <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Recruiter Login</button>
                </div>
              </div>
        </div>
        </ng-container>

        <ng-container *ngIf="isSmallScreen">

            <div class="col">

                <div class="row pl justify-content-center">
                  <div class="col-auto">
                    <span (click)="clientLogin()" class="custom-link">
                      <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
                    </span>
                  </div>
                </div>
            </div>
      
            <div class="col">
                <div class="row pr text-end justify-content-center">
                    <div class="col-auto">
                    <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">Recruiter Login</button>
                    </div>
                </div>
            </div>
      
        </ng-container>
    </div>
    <!-- end header -->

    <!-- start middle -->
    <div class="row mt-4 align-items-center">

        <div class="{{isSmallScreen ? 'col-12' : 'col-6'}}">

        <!-- start main form left side -->
        <div class="row pl pr">
            <div class="col-12">

            <div class="row">
                <div class="{{isSmallScreen ? 'col-12' : 'col-8 offset-2'}}">

                <div class="row">
                    <div class="col">
                    <span class="login-header">
                        Reset Password
                    </span>
                    </div>
                </div>

                <div class="row mt-2">
                    <div class="col">
                    <span class="login-header2">
                        Update your password
                    </span>
                    </div>
                </div>

                <div *ngIf="!isValid" class="row mt-5">
                    <div class="col-8 offset-2 text-center">
                        <div class="countdown-container">
                            <p>{{ redirectMessage }}. You will be redirected to login in <b>{{ countdown }}</b> seconds.</p>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="isValid"  class="row mt-username">
                    <div class="col">

                    <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">

                        <div class="row mt">
                            <div class="col">
                                <div class="form-group">
                                <div>
                                    <label for="password1" class="login-labels">Password:</label>
                                </div>
                                <!-- <input class="form-control curved" type="password" id="password1" formControlName="password1"> -->
                                <p-password id="password1" [toggleMask]="true" [style]="{'width':'100%'}" formControlName="password1"></p-password>

                                <div class="text-danger mt-2" *ngIf="submitted && (resetForm.controls['password1'].invalid || 
                                (resetForm.controls['password1'].errors && resetForm.controls['password1'].errors['required'])
                                )">
                                    Password is required
                                </div>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <div class="col">
                                <div class="form-group">
                                <div>
                                    <label for="password2" class="login-labels">Confirm Password:</label>
                                </div>
                                <!-- <input class="form-control curved" type="password" id="password2" formControlName="password2"> -->
                                <p-password id="password2" [feedback]="false" [toggleMask]="true" [style]="{'width':'100%'}" formControlName="password2"></p-password>

                                <div class="text-danger mt-2" *ngIf="submitted && ( 
                                (resetForm.controls['password2'].errors && resetForm.controls['password2'].errors['required'])
                                )">
                                    Confirm Password is required
                                </div>

                                <div  class="text-danger"  *ngIf="resetForm.controls['password2'].hasError('passwordMismatch')">
                                    Passwords do not match.
                                </div>

                                </div>
                            </div>
                        </div>

                        <div class="row mt-sign-in">
                        <div class="col-12">
                            <button [disabled]="processing" class="btn btn-primary form-control rounded-pill" type="submit">Reset
                            <div *ngIf="processing" class="spinner-border spinner-border-sm" role="status">
                            </div>
                            </button>
                        </div>
                        </div>

                    </form>
                    </div>
                </div>

                </div>
            </div>
            </div>
        </div>
        <!-- end main form left side -->

        </div>
        <div *ngIf="!isSmallScreen" class="col-6">
            <img src="assets/img/recruiter-login-dark.png" alt="recruiter-image">
        </div>
    
    </div>
    <!-- end middle -->

    <!-- start footer -->
    <div class="row footer mt-3 pt-3 pb-3 header-border">
        <div class="col ml">

            <p class="contact">
                <a class="social-links" href="https://www.facebook.com/profile.php?id=61558094197171" target="_blank">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a class="social-links" href="https://twitter.com/xCV_ai3" target="_blank">
                  <!-- <i class="fa fa-twitter ml-1" aria-hidden="true"></i> -->
                  <img src="assets/img/twitter.png" class="twit-img" alt="">
                </a>
                <a class="social-links" href="https://www.linkedin.com/company/xcv-ai/" target="_blank">
                  <i class="fa fa-linkedin ml-1" aria-hidden="true"></i>
                </a>
                <a class="social-links" href="https://www.instagram.com/xcvai3/" target="_blank">
                  <i class="fa fa-instagram ml-1" aria-hidden="true"></i>
                </a>
                <a class="social-links" href="https://youtube.com/@xCVai?si=gkjVhNjyxrAsXFA8" target="_blank">
                  <i class="fa fa-youtube-play ml-1" aria-hidden="true"></i>
                </a>
              </p>

        </div>
        <div class="col text-end mr">
            <span class="rights-reserved">
                &copy;2023 All rights reserved
            </span>
        </div>

    </div>
    <!-- end footer -->
