<!-- start header -->
<div class="row align-items-center mt-3">

    <ng-container *ngIf="!isSmallScreen">
        <div class="col-6">

          <div class="row pl-3 justify-content-center">
            <div class="col-12">

              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
                  
            </div>
          </div>
  
        </div>
  
      <div class="col-6">
        
        <div class="row pr-3 text-end justify-content-center">
          <div class="col-12">
            <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isSmallScreen">
        <div class="col">

          <div class="row pl justify-content-center">
            <div class="col-auto">
              <span (click)="clientLogin()" class="custom-link">
                <img src="assets/img/xcv-logo-header.svg" alt="xcv-logo">
              </span>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row pr text-end justify-content-center">
            <div class="col-auto">
              <button (click)="recruiterLogin()" class="btn btn-primary btn-block rounded-pill2 recruiter-login-btn">HR / Recruiter Login</button>
            </div>
          </div>
        </div>
      </ng-container>
</div>
<!-- end header -->

<!-- start middle -->
<div class="row mt-4 align-items-center">
    <div class="{{isSmallScreen ? 'col-12' : 'col-6'}}">

      <!-- start main form left side -->
      <div class="row pl pr">
        <div class="col-12">

            <div class="row">
                <div class="{{isSmallScreen ? 'col-12' : 'col-8 offset-2'}}">

                    <div class="row">
                        <div class="col">
                          <span class="login-header">
                            Company Register
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <span class="field-req">
                            <span class="field-req-ask">*</span> Fields Required
                          </span>
                        </div>
                      </div>

                      <div class="row mt-4">
                        <div class="col">

                          <form [formGroup]="registerCompanyForm" (ngSubmit)="onSubmit()">

                            <!-- start company name -->
                            <div class="row mt-2">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="companyName" class="login-labels">Company Name: <span class="field-req-ask">*</span></label>
                                  </div>
                                  <input placeholder="My Company LLC" class="mt form-control curved" type="text" id="companyName" formControlName="companyName">
                                  <div class="text-danger mt-2" *ngIf="submitted && (registerCompanyForm.controls['companyName'].invalid || 
                                  (registerCompanyForm.controls['companyName'].errors && registerCompanyForm.controls['companyName'].errors['required'])
                                  )">
                                    Company Name is required
                                  </div>
        
                                </div>
                              </div>
                            </div>
                            <!-- end company name -->
                                    
                            <!-- start company email -->
                            <div class="row mt-3">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="companyEmail" class="login-labels">Email: <span class="field-req-ask">*</span></label>
                                  </div>
                                  <input placeholder="name@company.com" class="mt form-control curved" type="text" id="companyEmail" formControlName="companyEmail">
                                  <div class="text-danger mt-2" *ngIf="submitted && (
                                  (registerCompanyForm.controls['companyEmail'].errors && registerCompanyForm.controls['companyEmail'].errors['required'])
                                  )">
                                    Company Email is required
                                  </div>
                                  <div class="text-danger mt-2" *ngIf="submitted && (
                                  (registerCompanyForm.controls['companyEmail'].errors && registerCompanyForm.controls['companyEmail'].errors['emailInvalid'])
                                  )">
                                    Company Email format is invalid
                                  </div>
        
                                </div>
                              </div>
                            </div>
                            <!-- end company email -->

                            <!-- start company phone -->
                            <div class="row mt-2">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="companyPhone" class="login-labels">Phone: <span class="field-req-ask">*</span></label>
                                  </div>
                                  <input placeholder="(123) 456-789-0123" class="mt form-control curved" type="text" id="companyPhone" formControlName="companyPhone">
                                  <div class="text-danger mt-2" *ngIf="submitted && (registerCompanyForm.controls['companyPhone'].invalid || 
                                  (registerCompanyForm.controls['companyPhone'].errors && registerCompanyForm.controls['companyPhone'].errors['required'])
                                  )">
                                    Company Phone is required
                                  </div>
        
                                </div>
                              </div>
                            </div>
                            <!-- end company phone -->

                            <!-- start company address -->
                            <div class="row mt-2">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="companyAddress" class="login-labels">Address:</label>
                                  </div>
                                  <input placeholder="123 Main Street, Toronto, ON MS52B4" class="mt form-control curved" type="text" id="companyAddress" formControlName="companyAddress">
                                </div>
                              </div>
                            </div>
                            <!-- end company address -->

                            <!-- start password1 -->
                            <!-- <div class="row mt-2">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="password1" class="login-labels">Password:</label>
                                  </div>

                                  <p-password id="password1" [toggleMask]="true" [style]="{'width':'100%'}" formControlName="password1"></p-password>

                                  <div class="text-danger mt-2" *ngIf="submitted && (registerCompanyForm.controls['password1'].invalid || 
                                  (registerCompanyForm.controls['password1'].errors && registerCompanyForm.controls['password1'].errors['required'])
                                  )">
                                      Password is required
                                  </div>
  
                                </div>
                              </div>
                            </div> -->
                            <!-- end password1 -->




                            <!-- start password2 -->
                            <!-- <div class="row mt-2">
                              <div class="col">
                                <div class="form-group">
                                  <div>
                                    <label for="password2" class="login-labels">Confirm Password:</label>
                                  </div>
                                  <p-password id="password2" [feedback]="false" [toggleMask]="true" [style]="{'width':'100%'}" formControlName="password2"></p-password>

                                  <div class="text-danger mt-2" *ngIf="submitted && ( 
                                    (registerCompanyForm.controls['password2'].errors && registerCompanyForm.controls['password2'].errors['required'])
                                    )">
                                        Confirm Password is required
                                    </div>
    
                                    <div  class="text-danger"  *ngIf="registerCompanyForm.controls['password2'].hasError('passwordMismatch')">
                                        Passwords do not match.
                                    </div>
    
                                </div>
                              </div>
                            </div> -->
                            <!-- end password2 -->


                            <!-- start submit button -->
                            <div class="row mt-5">
                              <div class="col">

                                <button [disabled]="processing" class="btn btn-primary form-control rounded-pill" type="submit">
                                  Register
                                  <div *ngIf="processing" class="spinner-border spinner-border-sm" role="status">
                                  </div>
                                </button>
                                
                              </div>
                            </div>
                            <!-- end submit button -->

                          </form>

                        </div>
                      </div>
        
                </div>
            </div>
    
        </div>
    </div>
      <!-- end main form left side -->

    </div>
    
    <div *ngIf="!isSmallScreen" class="col-6">
        <img src="assets/img/building.png" alt="recruiter-image">
    </div>

</div>
<!-- end middle -->

<!-- start footer -->
<div class="row footer mt-3 pt-3 pb-3 header-border" style="padding-left:2rem;padding-right:2rem">
  <div class="col ml">

    <p class="contact">
      <a class="social-links" href="https://www.facebook.com/profile.php?id=61558094197171" target="_blank">
        <i class="fa fa-facebook" aria-hidden="true"></i>
      </a>
      <a class="social-links" href="https://twitter.com/xCV_ai3" target="_blank">
        <!-- <i class="fa fa-twitter ml-1" aria-hidden="true"></i> -->
        <img src="assets/img/twitter.png" class="twit-img" alt="">
      </a>
      <a class="social-links" href="https://www.linkedin.com/company/xcv-ai/" target="_blank">
        <i class="fa fa-linkedin ml-1" aria-hidden="true"></i>
      </a>
      <a class="social-links" href="https://www.instagram.com/xcvai3/" target="_blank">
        <i class="fa fa-instagram ml-1" aria-hidden="true"></i>
      </a>
      <a class="social-links" href="https://youtube.com/@xCVai?si=gkjVhNjyxrAsXFA8" target="_blank">
        <i class="fa fa-youtube-play ml-1" aria-hidden="true"></i>
      </a>
    </p>

  </div> 
  <div class="col text-end mr">
  <span class="rights-reserved">
    &copy;2023 All rights reserved
  </span>
  </div>

</div>
<!-- end footer -->